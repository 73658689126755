import {atom} from "recoil";

const symbolsState = atom({
    key: "symbolState",
    default: {
        "symbols":
            [
                "1",
                "2",
                "3",
                "4",
                "5",
                "6",
                "7",
                "8",
                "9",
                "0",
                "%",
                ".",
                "(",
                ")",
                "00",
                "+",
                "-",
                "*",
                "/",
                "="
            ]
    }
})

export default symbolsState